<template>
  <div id="app">
    <navTopView v-bind:navData="navData"/>
    <main class="container-fluid main-view">
      <div class="row flex-xl-nowrap">
        <sideMenuView/>
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import navTopView from './views/public/navTop.vue'
import sideMenuView from './views/public/sideMenu'
import subtitles from './../public/js/subtitle'
import shareds from './../public/js/shared'
var shared = new shareds()
let subtitle = new subtitles()
export default {
  name: 'App',
  components: {
    navTopView,
    sideMenuView
  },
  data(){
    return {
      messaging:null,
      navData:{
        isBack:false,
        title:'管理後台',
        sub_tile:'儀表板',
        user_name:'',
        clinci_name:'',
        acvtion:{}
      }
    }
  },
  watch:{
    $route(to,from){
      let Owner = this
      console.log('to:',to)
      console.log('to.name:',to.name)
      console.log('from.name:',from.name)
      const userData = Owner.$store.state.userData
      console.log('userData:',userData)
      Owner.navData = subtitle.setSubTitleObj({name:to.name},Owner.navData)
      Owner.navData.user_name = userData.name
      Owner.navData.clinci_name = userData.com_name
    }
  },
  methods:{
    initUserInfo(){ //初始化登入作業
      let Owner = this
      let token = new URL(location.href).searchParams.get('token')
      const userData = Owner.$store.state.userData
      if ( !token ){ //沒有付token
        console.log('沒有token,',Owner.$store.state)
        if ( !userData.user_id ){ //檢查登入有沒有資訊
          //轉去登入介面
          //處理關閉或者重整畫面資料不消失
          let userInfo = localStorage.getItem('userInfo')
          console.log('created userInfo:',userInfo)
          if ( userInfo ){ //資料存在重新寫入
            this.$store.commit('setUserinfo',{
                userData:JSON.parse(userInfo)
            })
            return
          }
          //處理是否有hisToken
          //儲存後轉跳登入網址
          document.location.href = 'https://login-dev.dentalcon.tw'
          // document.location.href = 'http://localhost:3005'
          return
        }
      }else{ //有token
        //執行寫入登入資訊
        let tokenData = shared.decryptionUserInfo(token)
        this.$store.commit('setUserinfo',{
          userData:tokenData
        })
      }
      Owner.$router.push({ path: "/"})  
    },
    requestPermission(){ //請求推播權限
      console.log('Requesting permission...')
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        }
      })
    },
    
  },
  mounted(){
    console.log('mounted')
    
    
  },
  created(){
    //處理推播註冊
    // let firebaseObj = initializeApp({
    //     apiKey: "AIzaSyCRfGnfcDs7tBvesdDvsRJ4L8SXyBFq5z8",
    //     authDomain: "dcp-test-186fb.firebaseapp.com",
    //     projectId: "dcp-test-186fb",
    //     storageBucket: "dcp-test-186fb.appspot.com",
    //     messagingSenderId: "158257781334",
    //     appId: "1:158257781334:web:5c8499ba30688ee17e39f7",
    //     measurementId: "G-2KVHDBJJMT"
    // });
    // this.requestPermission()
    // getToken(getMessaging(firebaseObj), { vapidKey:"BPH7ayHdyyQBCm3NLeqDn3AD3UumdhO7yxDKDvpWCNaE_6ulVWkmYogA4zzm2agk3xMhTPh_rK0gaxqxW62EjDI" }).then((currentToken) => {
    //   if (currentToken) {
    //     console.log('currentToken:',currentToken)
    //     // Send the token to your server and update the UI if necessary
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // })
    // onMessage(getMessaging(firebaseObj), (payload) => {
    //   console.log('Message received. ', payload);
    //   // ...
    // });
    this.initUserInfo()
  }
}
</script>
<style lang="css" src="../public/css/dcp-base.css" scoped></style>
