const hisRoutes = [
    {
        path:'list',
        name:'his-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/his/list.vue')
    },
    {
        path:'add',
        name:'his-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/his/add.vue')
    },
    {
        path:'info',
        name:'his-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/his/info.vue')
    },
    {
        path:'edit',
        name:'his-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/his/edit.vue')
    },
]
export default hisRoutes