
const clinicRoutes = [
    {
        path:'list',
        name:'clinic-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/list.vue')
    },
    {
        path:'add',
        name:'clinic-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/add.vue')
    },
    {
        path:'info',
        name:'clinic-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/info.vue')
    },
    {
        path:'edit',
        name:'clinic-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/edit.vue')
    },
]
export default clinicRoutes