
const roleRoutes = [
    {
        path:'list',
        name:'company-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/list.vue')
    },
    {
        path:'add',
        name:'company-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/add.vue')
    },
    {
        path:'info',
        name:'company-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/info.vue')
    },
    {
        path:'edit',
        name:'company-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/edit.vue')
    },
]
export default roleRoutes