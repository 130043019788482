import roleRoutes from './roleRoutes'
import accountRoutes from './accountRoutes'
import companyRoutes from './companyRoutes'
import hisRoutes from './hisRoutes'
import clinicRoutes from './clinicRoutes'
import patientRoutes from './patientRoutes'
import dentlabRoutes from './dentlabRoutes'
import orderRoutes from './orderRoutes'
const routes = [
    {
        path:'/',
        name:'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
    },
    {
        path:'/role',
        name:'role',
        children:roleRoutes
    },
    {
        path:'/account',
        name:'account',
        children:accountRoutes
    },
    {
        path:'/company',
        name:'company',
        children:companyRoutes
    },
    {
        path:'/his',
        name:'his',
        children:hisRoutes
    },
    {
        path:'/clinic',
        name:'clinic',
        children:clinicRoutes
    },
    {
        path:'/patient',
        name:'patient',
        children:patientRoutes
    },
    {
        path:'/dentlab',
        name:'dentlab',
        children:dentlabRoutes
    },
    {
        path:'/order',
        name:'order',
        children:orderRoutes
    },
    
   
]
export default routes