
const dentlabRoutes = [
    {
        path:'list',
        name:'dentlab-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/list.vue')
    },
    {
        path:'add',
        name:'dentlab-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/add.vue')
    },
    {
        path:'info',
        name:'dentlab-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/info.vue')
    },
    {
        path:'edit',
        name:'dentlab-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/edit.vue')
    },
]
export default dentlabRoutes