
const accountRoutes = [
    {
        path:'list',
        name:'account-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/list.vue')
    },
    {
        path:'add',
        name:'account-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/add.vue')
    },
    {
        path:'info',
        name:'account-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/info.vue')
    },
    {
        path:'edit',
        name:'account-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/edit.vue')
    },
]
export default accountRoutes