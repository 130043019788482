class subtitles {
    //物件建立時產生
    constructor(){
        // this.navData = {
        //     sub_tile:'儀表板',
        //     isBack:false,
        //     acvtion:[]
        // }
    }

    setSubTitleObj(data,ary){
        ary.acvtion = []
        ary.isBack = false
        ary.isLink = null
        switch (data.name) {
            case 'dashboard':
              ary.sub_tile = '儀表板'
              break;
            case 'role-list':
              ary.sub_tile = '角色管理'
              break;
            case 'account-list':
              ary.sub_tile = '帳號管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/account/add'
                },
              ]
              break;
            case 'account-add':
              ary.sub_tile = '建立帳號'
              ary.isBack = true
              break;
            case 'account-info':
              ary.sub_tile = '帳號詳細資訊'
              ary.isBack = true
              break;
            case 'account-edit':
              ary.sub_tile = '編輯帳號'
              ary.isBack = true
              break;
            case 'company-list':
              ary.sub_tile = '公司管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/company/add'
                },
              ]
              break;
            case 'company-add':
              ary.sub_tile = '建立公司'
              ary.isBack = true
              break;
            case 'company-info':
              ary.sub_tile = '公司詳細資訊'
              ary.isBack = true
              break;
            case 'company-edit':
              ary.sub_tile = '編輯公司資訊'
              ary.isBack = true
              break;
            case 'his-list':
              ary.sub_tile = '掛號系統列表'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/his/add'
                },
              ]
              break;
            case 'his-add':
              ary.sub_tile = '建立掛號系統'
              ary.isBack = true
              break;
            case 'his-info':
              ary.sub_tile = '掛號系統詳細資訊'
              ary.isBack = true
              break;
            case 'his-edit':
              ary.sub_tile = '編輯掛號系統'
              ary.isBack = true
              break;
            case 'clinic-list':
              ary.sub_tile = '診所管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/clinic/add'
                },
              ]
              break;
            case 'clinic-add':
              ary.isBack = true
              ary.sub_tile = '建立診所'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/clinic/add'
                },
              ]
              break;
            case 'clinic-info':
              ary.isBack = true
              ary.sub_tile = '診所詳細資訊'
              ary.acvtion = []
              break;
            case 'clinic-edit':
              ary.isBack = true
              ary.sub_tile = '編輯診所資料'
              break;
            case 'patient-list':
              ary.sub_tile = '病患管理'
              break;
            case 'patient-info':
              ary.sub_tile = '病患詳細資訊'
              ary.isBack = true
              break;
            case 'dentlab-list':
              ary.sub_tile = '技工所管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/dentlab/add'
                },
              ]
              break;
            case 'dentlab-info':
              ary.sub_tile = '技工所詳細資訊'
              ary.isBack = true
              break;
            case 'dentlab-add':
              ary.sub_tile = '建立技工所'
              ary.isBack = true
              break;
            case 'dentlab-edit':
              ary.sub_tile = '建立技工所'
              ary.isBack = true
              break;
            case 'denture-order':
              ary.sub_tile = '訂單狀態'
              break;
            case 'order-info':
              ary.sub_tile = '訂單詳細資訊'
              ary.isBack = true
              break;
            default:
              ary.sub_tile = 'App.vue 未設定'
              break;
        }
        return ary
    }
}
export default subtitles