<template>
    <div class="side-menu">
        <ul class="main-menu">
            
            <li v-for="(mainItem,i)  in mainMenu" :key="i">
                <h4 class="mb-0">{{mainItem.name}}</h4>
                <ul class="sub-menu" v-if="mainItem.childMenu">
                    <li v-for="(childItem,n)  in mainItem.childMenu" :key="n">
                        <router-link class="router-link" :to="childItem.to">
                            <i class="align-middle fas fa-fw fs-19" v-bind:class="[childItem.icon]"></i> <span class="align-middle">{{childItem.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        
            
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'sideMenu',
        props:[
            'munu'
        ],
        data() {
        return {
                mainMenu:[
                    {
                        name:'總覽',
                        childMenu:[
                            {
                                name:'儀表板',
                                to:'/',
                                icon:'fa-tachometer-alt'
                            }
                            
                        ]
                    },
                    {
                        name:'帳號管理',
                        childMenu:[
                            {
                                name:'角色管理',
                                to:'/role/list',
                                icon:'fa-user-tag'
                            },
                            {
                                name:'帳號管理',
                                to:'/account/list',
                                icon:'fa-users'
                            },
                            {
                                name:'公司管理',
                                to:'/company/list',
                                icon:'fa-building'
                            },
                        ]
                    },
                    {
                        name:'掛號系統管理',
                        childMenu:[
                            {
                                name:'掛號系統列表',
                                to:'/his/list',
                                icon:'fa-square'
                            },
                        ]
                    },
                    {
                        name:'診所管理',
                        childMenu:[
                            {
                                name:'診所管理',
                                to:'/clinic/list',
                                icon:'fa-hospital'
                            },
                            {
                                name:'病人列表',
                                to:'/patient/list',
                                icon:'fa-address-book'
                            },
                        ]
                    },
                    {
                        name:'技工所管理',
                        childMenu:[
                            {
                                name:'技工所管理',
                                to:'/dentlab/list',
                                icon:'fa-drafting-compass'
                            },
                            {
                                name:'訂單狀態',
                                to:'/order/denture-order',
                                icon:'fa-clipboard-list'
                            },
                        ]
                    },
                    // {
                    //     name:'訂單管理',
                    //     childMenu:[
                    //         {
                    //             name:'帳單查詢',
                    //             to:'/bill/list',
                    //             icon:'fa-money-check-alt'
                    //         },
                    //         {
                    //             name:'訂單列表',
                    //             to:'/order/list',
                    //             icon:'fa-list-alt'
                    //         },
                    //         {
                    //             name:'已完成訂單',
                    //             to:'/finish-order/list',
                    //             icon:'fa-clipboard-check'
                    //         },
                    //     ]
                    // },

                ]
            } 
        },
        methods:{
            
        }
    }
</script>
<style scoped>
.router-link,.router-link>i{
    color:#6b6b6b;
}
.router-link:hover > i,.router-link:hover > span{
    color:#000000;
    font-weight: 900 !important;
}
.router-link-active,.router-link-active>i,.router-link-active>span{
    color:#000000;
    font-weight: 900 !important;
}
</style>