
const orderRoutes = [
    {
        path:'denture-order',
        name:'denture-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/order-list.vue')
    },
    {
        path:'info',
        name:'order-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/order/info.vue')
    },
    // {
    //     path:'edit',
    //     name:'his-edit',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/his/edit.vue')
    // },
]
export default orderRoutes
