import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router/index'
import { createStore } from 'vuex'
import stores from './store/index'
const router = createRouter({
    base: process.env.VUE_APP_BASE_APP_URL,
    history: createWebHistory(process.env.VUE_APP_BASE_APP_URL),
    routes,
})
const store = createStore(stores)
const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
